body {
  margin: 0;
  padding: 0;
  border: 0;
  color: #eef2f7;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-callout: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  /* touch-action: none; */
}

html {
  /* background-color: #0b1c2f; */
  height: 100%;
}

html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
